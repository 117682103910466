import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import BlogDetailHeader from '../components/BlogDetailHeader'
import BlogDetailTitle from '../components/BlogDetailTitle';
import BlogDetailContent from '../components/BlogDetailContent';
import BlogDetailNext from '../components/BlogDetailNext';
import BlogDetailPrev from '../components/BlogDetailPrev';
import { MainContext, useContext } from '../MainContext';
import { useParams } from 'react-router-dom';



export default function BlogDetail() {

    const [content, setContent] = useState();
    const [nextArticle, setNextArticle] = useState();
    const [prevArticle, setPrevArticle] = useState();
    const { slug } = useParams();

    const { axiosInstance } = useContext(MainContext);

    useEffect(() => {

        let initialFunc = () => {
            axiosInstance.post("/blog-detail/" + slug).then((res) => {
                setContent(res.data);
    
                if (res.data.prev) {
                    setPrevArticle(<BlogDetailPrev title={res.data.prev.title} url={res.data.prev.slug} />);
                }
    
                if (res.data.next) {
                    setNextArticle(<BlogDetailNext title={res.data.next.title} url={res.data.next.slug} />);
                }
            }).catch((err) => {
                //console.log(err)
            });
        }

        initialFunc();

    }, []);

    let htmlContent = content ? content.content : "";
    return (
        <Container className="mt-5 mb-5 blog-detail">
            <BlogDetailHeader category={content ? content.category.name : ""} image={content ? content.thumbnail : ""} />
            <BlogDetailTitle title={content ? content.title : ""} date={content ? content.date : ""} />
            <BlogDetailContent rawHTML={htmlContent} />
            <Row className="mt-5  justify-content-between">
                {prevArticle}
                {nextArticle}
            </Row>
        </Container>
    )
}
