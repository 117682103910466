import { Routes, Route } from 'react-router-dom';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Home from './pages/Home';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import Project from './pages/Project';
import Profile from './pages/Profile';
import { MainContext } from './MainContext';
import { useEffect, useState } from 'react';
import bs58 from 'bs58'
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import AuthGuard from './AuthGuard';
import AirDrop from './pages/AirDrop';
import CookieModal from './components/CookieModal';
import Cookies from 'js-cookie'
import { md5 } from 'js-md5';


function App() {

  AOS.init();

  const [provider, setProvider] = useState();
  const [publicAddress, setPublicAddress] = useState();
  const [signature, setSignature] = useState();
  const [publicKey, setPublicKey] = useState();
  const [bearerToken, setBearerToken] = useState(-1);
  const [metamaskAddress, setMetamaskAddress] = useState();
  const [bondPrice, setBondPrice] = useState();
  const [userProfile, setUserProfile] = useState();
  const [systemSettings, setSystemSettings] = useState();

  const navigate = useNavigate();

  let getMessageFromServer = (public_address) => {
    return axios.post(process.env.REACT_APP_API_BASE_URL + "/getMessageForLogin", { public_address: public_address }, (res) => {
      let data = res.data;
      return data;
    });
  }

  let getSignature = async (message, publicAddr) => {
    try {
      window.solana.signMessage(new TextEncoder().encode(message), 'utf8').then((data) => {
        let publicKey_ = data.publicKey.toBase58();
        let signature_ = bs58.encode(data.signature);
        setPublicKey(publicKey_)
        setSignature(signature_);
        let body = {
          public_address: publicAddr,
          public_key: publicKey_,
          signed_message: signature_
        };
        axios.post(process.env.REACT_APP_API_BASE_URL + "/login", body).then((res) => {
          let data = res.data;
          if (data.status === 1) {
            Swal.fire({
              title: 'Success',
              text: "Connected Successfully",
              icon: 'success',
            });
            if (data.data.metamask_address !== -1) {
              setMetamaskAddress(data.data.metamask_address);
            }
            setBearerToken(data.data.split("|")[1]);
            Cookies.set(md5('sessionToken'), data.data.split("|")[1]);
            setUserProfile(data.profile);
          }
          return data;
        });
      }).catch((e) => {
        Swal.fire({
          title: 'Error',
          text: e.message,
          icon: 'error',
        });
      });
    } catch (e) {
      Swal.fire({
        title: 'Error',
        text: e.message,
        icon: 'error',
      });
    }
  }

  let connectPhantom = async (is_logged = 0) => {
    try {
      if (bearerToken !== -1 && is_logged == 0) {
        navigate("/profile");
      } else {
        if (!window.solana || !window.solana.connect) {
          Swal.fire({
            title: 'Error',
            text: 'Please install phantom',
            icon: 'error',
          })
        } else {
          let provider = window.phantom.solana;

          let connection = await provider.connect();
          //let connection = await window.solana.connect();

          let publicKey = connection.publicKey.toString();

          setProvider(provider);
          setPublicAddress(publicKey);

          if (is_logged === 0) {
            let messageFromServer = await getMessageFromServer(publicKey);
            messageFromServer = messageFromServer.data.data;
            getSignature(messageFromServer, publicKey);

            navigate("/");
          }

          return provider;
        }
      }
    } catch (e) {
      Swal.fire({
        title: 'Error',
        text: e.message,
        icon: 'error',
      });
    }
  }

  let axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
  });

  axiosInstance.interceptors.request.use(
    config => {
      config.headers['Authorization'] = `Bearer ${bearerToken}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  const data = {
    connectPhantom,
    provider,
    setProvider,
    publicAddress,
    setPublicAddress,
    signature,
    setSignature,
    publicKey,
    setPublicKey,
    bearerToken,
    setBearerToken,
    axiosInstance,
    metamaskAddress,
    setMetamaskAddress,
    bondPrice,
    userProfile,
    setUserProfile,
    systemSettings
  };



  useEffect(() => {
    axiosInstance.post("get-all-settings").then((res) => {
      setSystemSettings(res.data);
    });
  
    if (bearerToken === -1) {
      let token = Cookies.get(md5("sessionToken"));
      if (typeof token !== "undefined") {
        setBearerToken(token);
      }
    }
  }, []);
  
  useEffect(() => {
    if (bearerToken !== -1) {
      //console.log("Bearer Token Set:", bearerToken);
      axiosInstance.interceptors.request.use(
        config => {
          config.headers['Authorization'] = `Bearer ${bearerToken}`;
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );

      axiosInstance.post("user/validate-token").then((res) => {
        if (res.data.status === 1) {
          setUserProfile(res.data.profile);
          connectPhantom(1)
        } else {
          setBearerToken(-1);
          Cookies.remove(md5("sessionToken"));
        }
      });
    }
  }, [bearerToken]);


  function NoMatch() {

    return (
      <div className="text-center d-flex justify-content-center align-items-center" style={{ height: "700px" }}>
        <h1>
          404
        </h1>
      </div>
    );
  }

  return (
    <>
      <MainContext.Provider value={data}>
        <Header price={"SOON!"} />
        <CookieModal />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/airdrop" element={<AirDrop />} />
          <Route exact path="/blog-post/:slug" element={<BlogDetail />} />
          <Route exact path="/project/:slug" element={<Project />} />
          <Route exact path="/profile" element={<AuthGuard Component={<Profile />} />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
        <Footer />
      </MainContext.Provider>
    </>
  );
}

export default App;
