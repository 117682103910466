import React from 'react'
import {MainContext, useContext} from './MainContext';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import { md5 } from 'js-md5';

export default function AuthGuard(props) {

    const { bearerToken, setBearerToken, connectPhantom } = useContext(MainContext);
    const auth = (bearerToken !== -1) ? true : null;

    return auth ? props.Component : <Navigate to="/" />;
}
