import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { MainContext, useContext } from '../MainContext';

export default function HomeRoadMap() {

    const { axiosInstance } = useContext(MainContext);

    const [roadmap, setRoadmap] = useState([]);

    useEffect(() => {

        let initialFunc = () => {
            axiosInstance.post("/home/roadmap").then((res) => {
                let data = res.data;
                setRoadmap(data);
            });
        }

        initialFunc();
    }, []);

    let statusIcons = {
        completed: () => {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="#fff"
                        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12S6.07 1.25 12 1.25 22.75 6.07 22.75 12 17.93 22.75 12 22.75zm0-20C6.9 2.75 2.75 6.9 2.75 12S6.9 21.25 12 21.25s9.25-4.15 9.25-9.25S17.1 2.75 12 2.75z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M10.58 15.58a.75.75 0 01-.53-.22l-2.83-2.83a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l2.3 2.3 5.14-5.14c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-5.67 5.67a.75.75 0 01-.53.22z"
                    ></path>
                </svg>
            );
        },
        processing: () => {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="#F6F7FC"
                        d="M9.99 22.75c-.2 0-.36-.04-.48-.09-.4-.15-1.08-.64-1.08-2.19v-6.45H6.09c-1.34 0-1.82-.63-1.99-1-.17-.38-.32-1.15.56-2.16l7.57-8.6c1.02-1.16 1.85-1.08 2.25-.93.4.15 1.08.64 1.08 2.19v6.45h2.34c1.34 0 1.82.63 1.99 1 .17.38.32 1.15-.56 2.16l-7.57 8.6c-.71.81-1.33 1.02-1.77 1.02zm3.94-20.01c-.03.04-.24.14-.57.52l-7.57 8.6c-.28.32-.32.52-.32.56.02.01.2.11.62.11h3.09c.41 0 .75.34.75.75v7.2c0 .5.09.72.13.78.03-.04.24-.14.57-.52l7.57-8.6c.28-.32.32-.52.32-.56-.02-.01-.2-.11-.62-.11h-3.09c-.41 0-.75-.34-.75-.75v-7.2c.01-.5-.09-.71-.13-.78z"
                    ></path>
                </svg>
            );
        },
        target: () => {
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    fill="none"
                    viewBox="0 0 28 28"
                >
                    <ellipse
                        cx="14"
                        cy="14"
                        fill="#fff"
                        rx="14"
                        ry="14"
                        transform="rotate(-90 14 14)"
                    ></ellipse>
                </svg>
            );
        }
    };

    let stepIcon = (el) => {
        if (el.status === 0) {
            return statusIcons.target()
        } else if (el.status === 1) {
            return statusIcons.completed()
        } else if (el.status === 2) {
            return statusIcons.processing()
        }
    }
    

    return (
        <Container className="roadmap">

            <div className="line">
                <div className="bar" style={{ width: (roadmap.length > 0 ? roadmap[0].bar_width : 100) + "%" }}></div>
            </div>


            {roadmap.map(item => (
                <div className="mission">
                    <div className={"icon " + (item.status === 1 || item.status === 2 ? "active" : "") }>{ stepIcon(item) }</div>
                    {/* <div className="toptitle">{item.date}</div> */}
                    <div className="toptitle">Soon</div>
                    <div className="title">{item.title}</div>
                </div>
            ))}

        </Container>
    )
}
