import React from 'react'
import { Row } from 'react-bootstrap'

export default function ProjectTokenInfo(props) {
  return (
    <div className="token-info">
      <div className="title">Token Info</div>
      <Row className="w-100 justify-content-around">
        <div className="col-lg-5 col-md-5 col-sm-12 info">
          <div className="key">
            <span className="ms-3">Token Symbol:</span>
          </div>
          <div className="value">{props.symbol}</div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 info">
          <div className="key">Softcap:</div>
          <div className="value">{props.exchangeRate}</div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 info">
          <div className="key">Hardcap:</div>
          <div className="value">{props.exchangePrice}</div>
        </div>

        <div className="col-lg-5 col-md-5 col-sm-12 info">
          <div className="key">Launch Time:</div>
          <div className="value">{props.launch}</div>
        </div>
      </Row>
    </div>
  )
}
