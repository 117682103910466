import React, { useEffect, useState } from 'react'
import { Connection, PublicKey, SystemProgram, Transaction } from '@solana/web3.js';
import { useWallet } from '@solana/wallet-adapter-react';
import { MainContext, useContext } from '../MainContext';
import Swal from 'sweetalert2';


export default function ProjectBuyForm(props) {

  const [amount, setAmount] = useState(0);
  //const [receiver, setReceiver] = useState();
  const {  publicAddress, bearerToken, provider } = useContext(MainContext);
  const { axiosInstance, connectPhantom } = useContext(MainContext);


  function plusAmount() {
    let check = parseInt(amount) + 1;
    if (check <= props.max) {
      setAmount(check);
    } else {
      setAmount(props.max);
    }
  }

  function minusAmount() {
    let check = amount - 1;
    if (check >= props.min) {
      setAmount(check);
    } else {
      setAmount(props.min);
    }
  }

  const handleChange = (e) => {
    const value = e.target.value;

    // Regex to allow only numbers and a single decimal point
    if (value === '' || /^[0-9]+(\.[0-9]*)?$/.test(value)) {
      setAmount(value);
    }
  }

  let icons = {
    minus: () => {
      return (
        <svg width="10" height="4" viewBox="0 0 10 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.4 3.825C2.16667 3.825 1.925 3.8 1.675 3.75C1.425 3.68333 1.21667 3.53333 1.05 3.3C0.883333 3.05 0.8 2.64167 0.8 2.075C0.8 1.50833 0.883333 1.10833 1.05 0.875C1.23333 0.641667 1.45 0.5 1.7 0.449999C1.95 0.383333 2.19167 0.35 2.425 0.35H8.275C8.525 0.35 8.775 0.383333 9.025 0.449999C9.275 0.5 9.48333 0.65 9.65 0.899999C9.81667 1.13333 9.9 1.525 9.9 2.075C9.9 2.64167 9.81667 3.05 9.65 3.3C9.5 3.53333 9.3 3.68333 9.05 3.75C8.8 3.8 8.54167 3.825 8.275 3.825H2.4Z" fill="black" />
        </svg>
      );
    },
    plus: () => {
      return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.825 11.9C5.275 11.9 4.875 11.8167 4.625 11.65C4.39167 11.4833 4.24167 11.2667 4.175 11C4.125 10.7333 4.1 10.45 4.1 10.15V2.05C4.1 1.75 4.125 1.475 4.175 1.225C4.24167 0.974999 4.39167 0.766666 4.625 0.6C4.875 0.433333 5.28333 0.349999 5.85 0.349999C6.4 0.349999 6.79167 0.433333 7.025 0.6C7.275 0.766666 7.425 0.983333 7.475 1.25C7.54167 1.51667 7.575 1.79167 7.575 2.075V10.175C7.575 10.4583 7.54167 10.7333 7.475 11C7.425 11.2667 7.275 11.4833 7.025 11.65C6.79167 11.8167 6.39167 11.9 5.825 11.9ZM1.675 7.85C1.40833 7.85 1.15 7.825 0.9 7.775C0.65 7.725 0.441667 7.575 0.275 7.325C0.125 7.075 0.05 6.675 0.05 6.125C0.05 5.54167 0.133333 5.13333 0.3 4.9C0.466667 4.66667 0.683333 4.525 0.95 4.475C1.21667 4.40833 1.5 4.375 1.8 4.375H9.875C10.175 4.375 10.4583 4.40833 10.725 4.475C10.9917 4.525 11.2 4.675 11.35 4.925C11.5167 5.15833 11.6 5.56667 11.6 6.15C11.6 6.7 11.5167 7.1 11.35 7.35C11.2 7.58333 11 7.725 10.75 7.775C10.5167 7.825 10.2583 7.85 9.975 7.85H1.675Z" fill="black" />
        </svg>
      );
    },
  };


  useEffect(() => {
    let initialFunc = () => {
      setAmount(props.min);
    }

    initialFunc();
    //setReceiver(props.receiver);
  }, []);


  /*

  const handleTransfer = async (sender, to, amount) => {
    try {
      const connection = new Connection('https://bold-flashy-silence.solana-mainnet.quiknode.pro/e2d96a7bb3e557f8010d78dce4ceb483d25e27eb/');
      const senderPublicKey = new PublicKey(sender);
      const recipientPublicKey = new PublicKey(to);
      const lamports = parseInt(amount) * 10 ** 9;

      // Build transaction
      const transaction = new Transaction().add(
        SystemProgram.transfer({
          fromPubkey: senderPublicKey,
          toPubkey: recipientPublicKey,
          lamports: lamports
        })
      );

      //const { blockhash } = await connection.getLatestBlockhash('finalized');
      let blockhash = await axiosInstance.post("/user/get-recent-block-hash").then((res) => {
        return res.data.hash;
      });
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = new PublicKey(sender);

      const { signature } = await provider.signAndSendTransaction(transaction);
      console.log(signature);
      console.log(await connection.getSignatureStatus(signature));

    } catch (error) {
      console.log('Error:', error.message);
    }
  };

*/

const handleTransfer = async (sender, to, amount, rpcURL) => {
  //let provider = window.phantom.solana;
  try {
    const connection = new Connection(rpcURL);
    const senderPublicKey = new PublicKey(sender);
    const recipientPublicKey = new PublicKey(to);
    const lamports = amount * 10 ** 9;

    // Build transaction
    const transaction = new Transaction().add(
        SystemProgram.transfer({
            fromPubkey: senderPublicKey,
            toPubkey: recipientPublicKey,
            lamports: lamports
        })
    );

    //let blockhash = (await connection.getLatestBlockhash('finalized')).blockhash;
    let blockhash = await axiosInstance.post("/user/get-recent-block-hash").then((res) => {
      return res.data.hash;
    });
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = new PublicKey(sender);

    //const signedTransaction = await provider.signTransaction(transaction);

    //sendAndConfirmTransaction(connection, signedTransaction, senderPublicKey);

    const { signature } = await provider.signAndSendTransaction(transaction);

    //console.log(signature);

    //console.log(await connection.getSignatureStatus(signature));
    
    let form = {
      presale_slug: props.presaleSlug,
      amount: lamports,
      hash: signature
    };
    axiosInstance.post("/user/join-presale", form).then((res) => {
      Swal.fire({
        title: res.data.status == 1 ? "Success" : "Failed",
        icon: res.data.status == 1 ? "success" : "error",
        text: res.data.message
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    });
    
} catch (error) {
  console.error(error);
    Swal.fire({
        title: "Error",
        icon: "error",
        text: error.message
    });
}
};

  //console.log(provider);

  //handleTransfer(publicAddress, props.receiver, amount);

  const handleForm = async (e) => {
    e.preventDefault();
    if (bearerToken === -1) {
      
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Firstly you need to connect your Phantom wallet"
      });
      
      //connectPhantom();
    } else {
      if (amount < props.min || amount > props.max) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "Please enter an amount between minimum and maximum"
        });
      } else {
        handleTransfer(publicAddress, props.receiver, amount, props.rpc);
      }
    }
  }


  return (
    <form className="buy-form" onSubmit={(e) => { handleForm(e); }}>

      <div className="left">

        <div className="limits">
          <div className="title">Amount:</div>
          <div className="subtitle">Min: {props.min} {props.coin} - Max: {props.max} {props.coin}</div>
        </div>

        <div className="value-zone">
          <button type="button" className="minus" onClick={() => { minusAmount() }}>{icons.minus()}</button>
          <input type="text" name="amount" value={amount} onChange={(e) => { handleChange(e) }} />
          <button type="button" className="plus" onClick={() => { plusAmount() }}>{icons.plus()}</button>
          <button type="button" className="max" onClick={() => { setAmount(props.max) }}>Max</button>
        </div>
      </div>

      <div className="right">
        <button className="send" disabled={props.sendDisabled === 1 ? "disabled" : ""}>Send</button>
      </div>

    </form>
  )
}
