import React from 'react'
import { Link } from 'react-router-dom'

export default function BlogDetailNext(props) {
    return (
        <Link to={"/blog-post/" + props.url} className="btn-go">
            <div className="text me-0">
                {props.title}
            </div>
            <div className="icon next">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.215 13.75H5V16.25H20.215L13.51 22.955L15.2775 24.7225L25 15L15.2775 5.27753L13.51 7.04503L20.215 13.75Z" fill="black" />
                </svg>
            </div>
        </Link>
    )
}
