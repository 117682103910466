import React, { useEffect, useRef, useState } from "react";
import { Container, Spinner } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { MainContext, useContext } from '../MainContext';
import 'swiper/css';
import 'swiper/css/autoplay';

export default function HomePriceSwiper() {

  const swiperRef = useRef();
  const { axiosInstance } = useContext(MainContext);
  const [content, setContent] = useState();

  useEffect(() => {

    let initialFunc =  () => {
      axiosInstance.post("/home/token-slider").then((res) => {
        let data = res.data;
  
        let temp = [];
        data.map((el, key) => {
          temp.push(<>
            <SwiperSlide className="slider-item" key={key}>
              <img src={"/images/price-slider-item-bg.png"} alt={""} className="btn-bg" />
              <div className="text">
                <img src={el.symbol} alt={""} />
                {el.value}
              </div>
            </SwiperSlide>
          </>);
        });
  
        setContent(temp);
      });
    }

    initialFunc();
    
  }, []);


  return (
    <Container className="price-slider mt-5 ">
      <div className="d-flex justify-content-between align-items-center">
        <button className="btn-action" onClick={() => swiperRef.current.slidePrev()}>
          <i className="fas fa-arrow-left"></i>
        </button>
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          spaceBetween={30}
          centeredSlides={false}
          slidesPerView={5}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
            pauseOnMouseEnter: true
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="slider-bg ms-3 me-3 p-4"
          breakpoints={{
            1450: {
              slidesPerView: 5,
            },
            1024: {
              slidesPerView: 3.5,
            },
            768: {
              slidesPerView: 2,
            },
            550: {
              slidesPerView: 1
            },
            430: {
              slidesPerView: 1
            },
            375: {
              slidesPerView: 1
            },
            320: {
              slidesPerView: 1
            }
          }}
        >
          {content ? content : <div className="text-center"><Spinner animation="border" variant={"light"} /></div>}

        </Swiper>
        <button className="btn-action" onClick={() => swiperRef.current.slideNext()}>
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </Container>
  )
}
