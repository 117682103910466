import React, { useEffect, useState } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import ProjectInfo from '../components/ProjectInfo'
import ProjectCounter from '../components/ProjectCounter'
import ProjectBuyForm from '../components/ProjectBuyForm'
import ProjectProgressBar from '../components/ProjectProgressBar'
import ProjectTokenInfo from '../components/ProjectTokenInfo'
import ProjectTabs from '../components/ProjectTabs'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MainContext, useContext } from '../MainContext'
import ProjectSold from '../components/ProjectSold'
import Cookies from 'js-cookie'
import { md5 } from 'js-md5';

export default function Project() {

    const { slug } = useParams();
    const { axiosInstance, userProfile, bearerToken } = useContext(MainContext);

    //const [projectDetail, setProjectDetail] = useState(0);
    const [sendDisabled, setSendDisabled] = useState(0);

    const [content, setContent] = useState();
    const [receiverWallet, setReceiverWallet] = useState();
    const [counterSection, setCounterSection] = useState(<div className="mt-5 mb-5 text-center"><Spinner animation="border" variant="dark" /></div>);
    
    const [barHardcap, setBarHardcap] = useState(0);
    const [barRemaining, setBarRemaining] = useState(0);

    const navigate = useNavigate();



    useEffect(() => {

        let initialFunc = () => {
            /*
            let token = Cookies.get(md5("sessionToken"));
            if (typeof token != "undefined") {
                connectPhantom(1)
                setBearerToken(token);
            }
            */

            let user_id = userProfile ? userProfile.user_id : 0;
            axiosInstance.post("/project-detail/" + slug + "?user_id=" + user_id).then(async (res) => {
                if (res.data.status === -1) {
                    navigate("/");
                }
                setContent(res.data);

                setBarHardcap(res.data.hardcap);
                setBarRemaining(res.data.remaining);

                setReceiverWallet(res.data.receiver_address);

                //const response = await fetch('http://worldtimeapi.org/api/ip');
                const data = await axiosInstance.get("/utc-datetime").then((ress) => { return ress.data });

                //const data = await response.json();
                let now = new Date(data.utc_datetime);
                let start = new Date(res.data.presale_start).getTime();
                let finish = new Date(res.data.presale_finish).getTime();

                if (now < start) {
                    setReceiverWallet("");
                    setSendDisabled(1);
                    setCounterSection(<ProjectCounter title={res.data.status.label} date={start} coin={res.data.token_shortening} />);
                } else if (now >= start && now <= finish) {
                    setCounterSection(<ProjectCounter title={res.data.status.label} date={finish} coin={res.data.token_shortening} />);
                } else {
                    setReceiverWallet("");
                    setSendDisabled(1);
                    setCounterSection(<ProjectSold />);
                }

                if (res.data.status.label === "Soon") {
                    setReceiverWallet("");
                    setCounterSection("");
                    setSendDisabled(1);
                }

                if (res.data.remaining === 0) {
                    setReceiverWallet("");
                    setSendDisabled(1);
                }

                if (res.data.is_joined === 1) {
                    setReceiverWallet("");
                    setSendDisabled(1);
                }
            });

            

            setInterval(() => {
                if (sendDisabled === 0) {
                    axiosInstance.post("/project-detail/" + slug + "?user_id=" + user_id).then((res) => {
                        setBarHardcap(res.data.hardcap);
                        setBarRemaining(res.data.remaining);
                    });   
                }
            }, 10000);
  
        }

        initialFunc();
    }, [userProfile]);



    return (
        <Container className="project mt-5 mb-5">
            {content
                ? <>
                    {content ? window.scrollTo({ top: 0, behavior: 'smooth' }) : ""}
                    <ProjectInfo thumbnail={content.thumbnail} logo={content.token_icon} description={content.description} networkIcon={content.chain.icon} socials={content.socials} />

                    <Link to={"https://x.com/007BondPad/status/1818708218341998717"} target='blank' className='d-flex justify-content-center align-items-center mt-3 mb-2'><img src={"/images/lppowered.png"} alt='partner'/></Link>

                    <div className="counter-zone">
                        <div className="title-zone">
                            <div className="toptitle">Bondpad</div>
                            <div className="title">{content.status.label}</div>
                        </div>
                    </div>

                    {counterSection}

                    <ProjectBuyForm presaleSlug={slug} min={content.min_send_size} max={content.max_send_size} rpcURL={content.chain.url} coin={content.chain.basecoin} receiver={receiverWallet} sendDisabled={sendDisabled} rpc={content.chain.url} />

                    <ProjectProgressBar coin={content.chain.basecoin} hardcap={barHardcap} remaining={barRemaining} />

                    <ProjectTokenInfo symbol={content.token_shortening} exchangeRate={content.exchange_rate} exchangePrice={content.exchange_price} launch={1 ? "In 2 Days After Presale" : content.launch} />

                    <ProjectTabs content={content.details} />
                </>
                : <div className="text-center mt-5 mb-5 pt-5 pb-5"><Spinner animation="border" role="status"></Spinner></div>}
        </Container>
    )
}
