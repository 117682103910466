import React, { useState } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import { MainContext, useContext } from '../MainContext'
import { Link } from 'react-router-dom';

export default function AirDrop() {

    const [wallet, setWallet] = useState();
    const [loaderText, setLoaderText] = useState("Check");
    const { axiosInstance } = useContext(MainContext);
    const [rows, setRows] = useState();

    function handleSubmit(e) {
        e.preventDefault();
        setLoaderText(<Spinner animation="border" variant='dark' />);
        axiosInstance.post("/airdrop-checker", { wallet_address: wallet }).then((res) => {
            console.log(res.data);
            let result = [];
            if (res.data.status === 1) {
                //console.log(res.data);
                let links = res.data.data;                
                
                links.map((el) => {
                    result.push(<>
                        <div className="table-row" key={0} >
                            <div className='justify-content-start'><Link to={el.links} target="_blank" className='text-dark fs-6 text-start overflow-auto'>{el.links}</Link></div>
                            <div>{el.amount}</div>
                            <div>You Win</div>
                        </div>
                    </>);
                });

                
            } else {
                result.push(<>
                    <div className="table-row" key={0} >
                        <div>{res.data.message}</div>
                        <div>0</div>
                        <div>You Didn't Win</div>
                    </div>
                </>);
            }

            setRows(result);
            setLoaderText("Check");
        });
    }

    return (
        <Container className='mt-5 mb-5 airdrop'>
            <div className='home'>
                <div className="subscribe row">
                    <img src={"/images/home-subscribe-bg.png"} alt={""} className="bg-img" />
                    <div className="content col-lg-6 col-md-12 col-sm-12">
                        <div className="title">AIRDROP CHECKER</div>
                        <form onSubmit={(e) => { handleSubmit(e) }}>

                            <div className="email-input">
                                <img src={"/images/wallet-icon.png"} alt={""} />
                                <input type="text" placeholder="Wallet Address" onChange={(e) => { setWallet(e.target.value); }} required />
                            </div>

                            <button disabled={loaderText === "Check" ? false : true}>
                                <img src={"/images/subscribe-btn-bg.png"} alt={""} />
                                <span id='loader'>{loaderText}</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>


            <div className="mt-5 profile">
                <div className="presale">
                    <div className="toptitle">Results</div>
                    <div className="title">Win Tweets</div>

                    <div className="table-zone">
                        <div className="table-head">
                            <div>Tweet</div>
                            <div>Amount</div>
                            <div>Status</div>
                        </div>

                        <div className="table-rows">

                            {rows}

                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}
