import React from 'react'
import { Link } from 'react-router-dom'

export default function HomeProjectItem(props) {
  return (
    <div className="project-item">
      <div className="image-zone">
        <img src={props.networkIcon} alt={""} className="network-icon" />
        <img src={props.thumbnail} alt={""} className="thumbnail" />
      </div>
      <div className="main-zone">
        <img src={props.logo} alt={""} className="logo" />
        <div className="description">{props.description}</div>
      </div>
      <div className="actions-zone">
        <div className="infos row">

          <div className="info col-6">
            <img src={"/images/project-info-btn-bg.png"} alt={""} />
            <div className="text">
              {/* <div className="value">{props.maxSize}</div> */}
              <div className="value">Soon</div>
              <div className="title">Max Size</div>
            </div>
          </div>

          <div className="info col-6">
            <img src={"/images/project-info-btn-bg.png"} alt={""} />
            <div className="text">
              {/* <div className="value">{props.hardcap}</div> */}
              <div className="value">Soon</div>
              <div className="title">Hardcap</div>
            </div>
          </div>

          <div className="info col-6">
            <img src={"/images/project-info-btn-bg.png"} alt={""} />
            <div className="text">
              <div className="value">{props.chain}</div>
              <div className="title">Chain</div>
            </div>
          </div>

          <div className="info col-6">
            <img src={"/images/project-info-btn-bg.png"} alt={""} />
            <div className="text">
              {/* <div className="value">{props.launch}</div> */}
              <div className="value" style={{ fontSize: "11px", marginBottom: ".25rem" }}>In 2 Days After Presale</div>
              <div className="title">Launch</div>
            </div>
          </div>

        </div>
        <Link to={false ? "#" : "/project/" + props.slug}>
          <div className="btn-join">
            <img src={"/images/project-btn-bg.png"} alt={""} />
            <div className="text">Join</div>
          </div>
        </Link>
      </div>
    </div>
  )
}
