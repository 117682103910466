import React from 'react'

export default function ProjectProgressBar(props) {

  // (sayi * yuzde) / 100
  let calc = props.hardcap / 100;
      calc = (props.hardcap - props.remaining) / calc;
      calc = Math.floor(calc);
      calc = calc < 1 ? 1 : calc;

  return (
    <div className="progress-zone">

      <div className="custom-progress">
        <div className="bar" style={{ width: calc + "%" }}>
          <div className="icon"></div>
        </div>
      </div>

      <div className="text-zone">
        <span>Remaining: {props.remaining} {props.coin}</span>
        <span>Total Hardcap: {props.hardcap}  {props.coin}</span>
      </div>
    </div>
  )
}
