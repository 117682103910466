import React from 'react'
import { Link } from 'react-router-dom'

export default function BlogItem(props) {
    return (
        <Link to={"/blog-post/" + props.slug} className="item col-lg-4 col-md-4 col-sm-12">
            <div className="image-zone">
                <img className="chain-logo" src={props.chainIcon} alt="" />
                <img className="thumbnail" src={props.thumbnail} alt="" />
            </div>
            <div className="title">{props.title}</div>
            <div className="date">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_57_25)">
                        <path d="M17.0001 3H21.0001C21.2653 3 21.5196 3.10536 21.7072 3.29289C21.8947 3.48043 22.0001 3.73478 22.0001 4V20C22.0001 20.2652 21.8947 20.5196 21.7072 20.7071C21.5196 20.8946 21.2653 21 21.0001 21H3.00006C2.73484 21 2.48049 20.8946 2.29295 20.7071C2.10542 20.5196 2.00006 20.2652 2.00006 20V4C2.00006 3.73478 2.10542 3.48043 2.29295 3.29289C2.48049 3.10536 2.73484 3 3.00006 3H7.00006V1H9.00006V3H15.0001V1H17.0001V3ZM15.0001 5H9.00006V7H7.00006V5H4.00006V9H20.0001V5H17.0001V7H15.0001V5ZM20.0001 11H4.00006V19H20.0001V11ZM6.00006 14H8.00006V16H6.00006V14ZM10.0001 14H18.0001V16H10.0001V14Z" fill="#959595" />
                    </g>
                    <defs>
                        <clipPath id="clip0_57_25">
                            <rect width="24" height="24" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                {props.date}
            </div>
        </Link>
    )
}
