import React, { useState } from 'react'
import { MainContext, useContext } from '../MainContext';
import Swal from 'sweetalert2';

export default function HomeSubscribe() {

    const { axiosInstance } = useContext(MainContext);

    const [email, setEmail] = useState();

    let handleSubmit = (e) => {
        e.preventDefault();

        let form = {email : email};

        axiosInstance.post("home/join-email", form).then((res) => {
            Swal.fire({
                icon: res.data.status === 1 ? "success" : "error",
                title : res.data.status === 1 ? "Success" : "Error",
                text: res.data.message
            });
        });

    }
 
    return (
        <div className="subscribe mb-5">
            <img src={"/images/home-subscribe-bg.png"} alt={""} className="bg-img" />
            <div className="content">
                <div className="features">TRUSTWORTHY-<span>PRACTICAL</span>-<span>PROFITABLE</span>-EASY TO USE</div>
                <div className="title">Currently On Solana!</div>
                <form onSubmit={(e) => { handleSubmit(e) }}>

                    <div className="email-input">
                        <img src={"/images/envelope.png"} alt={""} />
                        <input type="email" placeholder="Subscribe Your E-mail" onChange={(e) => { setEmail(e.target.value); }} required/>
                    </div>

                    <button>
                        <img src={"/images/subscribe-btn-bg.png"} alt={""} />
                        <span>Send Mail</span>
                    </button>
                </form>
            </div>
        </div>
    )
}
