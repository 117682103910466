import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import HomeNewsItem from './HomeNewsItem';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { Link } from 'react-router-dom';
import { MainContext, useContext } from '../MainContext';

export default function HomeNews() {

    const [newsComponents, setNews] = useState([]);

    const { axiosInstance, systemSettings } = useContext(MainContext);

    useEffect(() => {

        axiosInstance.post("/home/news").then((res) => {
            let tempComponents = [];
            res.data.map((el, key) => {
                return tempComponents.push(<Link to={"/blog-post/"+el.slug} key={key}><HomeNewsItem key={key} thumbnail={el.thumbnail} title={el.title} category={el.category.name} date={el.date} /></Link>);
            });
            setNews(tempComponents);
        });

        /*
        if (newsComponents.length < 1) {
            axios.get("http://localhost:3000/news.json", {}).then((res) => {
                let tempComponents = [];
                res.data.map((el, key) => {
                    return tempComponents.push(<Link to={"#"} key={key}><HomeNewsItem key={key} thumbnail={el.thumbnail} title={el.title} category={el.category} date={el.date} /></Link>);
                });
                setNews(tempComponents);
            });
        }
        */

    }, []);

    return (
        <Container className="news mb-5">

            <Row>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    {newsComponents}

                    <Link to={"/blog"} className="d-flex justify-content-center align-items-center mt-5">
                        <button className="load-more mt-3">
                            <img src={"/images/load-more-btn-bg.png"} alt={""} />
                            <span>See More</span>
                        </button>
                    </Link>
                </div>
                {
                    systemSettings ?
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <TwitterTimelineEmbed
                            sourceType="timeline"
                            screenName={systemSettings.home_tweets_username}
                            theme="dark"
                            options={{ height: 950 }}
                        />
                    </div>
                    :
                    <></>
                }
            </Row>

            <br />
            <br />
        </Container>
    )
}
