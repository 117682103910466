import React, { useEffect, useState } from 'react'
import { MainContext, useContext } from '../MainContext';
import { useNavigate } from 'react-router-dom';

export default function ProjectCounter(props) {

  const [multiplier, setMultiplier] = useState(1);
  const [days, setDays] = useState();
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();

  const { userProfile } = useContext(MainContext);

  const navigate = useNavigate();
  //let history = useHistory();


  useEffect(() => {

    let initialFunc = () => {
      if (userProfile) {
        setMultiplier(userProfile.multiplier);
      }
    }

    initialFunc();

  }, []);

  var x = setInterval(function () {
    var now = new Date().getTime();

    var distance = props.date - now;

    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    setDays(days);
    setHours(hours);
    setMinutes(minutes);
    setSeconds(seconds);

    //console.log(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");

    if (distance < 0) {
      clearInterval(x);
      window.location.reload();
    }
  }, 1000);

  return (
    <div className="counter-zone">

      <div className="counter">
        <div className="item day">
          <div className="value">{days < 0 ? 0 : days}</div>
          <div className="key">Day</div>
        </div>
        <div className="item hours">
          <div className="value">{hours < 0 ? 0 : hours}</div>
          <div className="key">hours</div>
        </div>
        <div className="item minutes">
          <div className="value">{minutes < 0 ? 0 : minutes}</div>
          <div className="key">minutes</div>
        </div>
        <div className="item seconds">
          <div className="value">{seconds < 0 ? 0 : seconds}</div>
          <div className="key">seconds</div>
        </div>
      </div>

      <div className="text">You’re Lucky! You Have <span id="multiplier">{multiplier}x</span> for <span>{props.coin}</span></div>
    </div>
  )
}
