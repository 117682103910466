import React, { useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import ProjectTabItemContent from './ProjectTabItemContent';

export default function ProjectTabs(props) {
  const [activeTab, setActiveTab] = useState('');
  const [tabList, setTabList] = useState([]);
  const [tabPanes, setTabPanes] = useState([]);

  useEffect(() => {
    if (props.content.length > 0) {
      setActiveTab(props.content[0].label);
    }
    
    let tempTabList = [];
    let tempTabPanes = [];

    props.content.forEach((el) => {
      tempTabList.push(
        <Nav.Link className="tab-menu-item" eventKey={el.label} id={el.label} key={el.label}>
          {el.label} {arrowIcon()}
        </Nav.Link>
      );

      tempTabPanes.push(
        <Tab.Pane className="tab-content" eventKey={el.label} key={el.label}>
          <ProjectTabItemContent title={el.label} rawHTML={el.content} />
        </Tab.Pane>
      );
    });

    setTabList(tempTabList);
    setTabPanes(tempTabPanes);
  }, [props.content]);

  useEffect(() => {
    document.querySelectorAll(".nav-item a").forEach((el) => {
      el.classList.remove("active-menu");
    });
    if (document.getElementById(activeTab)) {
      document.getElementById(activeTab).classList.add("active-menu");
    }
  }, [activeTab]);

  const arrowIcon = () => {
    return (
      <div className="icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.7815 7.33336H2.66683V8.6667H10.7815L7.2055 12.2427L8.14816 13.1854L13.3335 8.00003L8.14816 2.8147L7.2055 3.75736L10.7815 7.33336Z" fill="white" />
        </svg>
      </div>
    );
  };

  return (
    <Tab.Container
      id="left-tabs-example"
      activeKey={activeTab}
      onSelect={(k) => setActiveTab(k)}
    >
      <div className="tab-zone">
        <div className="tab-list">
          <div className="title">Project Details</div>
          <Nav.Item className="w-100">
            {tabList}
          </Nav.Item>
        </div>
        <div className="tab-contents">
          <Tab.Content>
            {tabPanes}
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
}
