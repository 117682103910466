import React from 'react'
import { Link } from 'react-router-dom'

export default function BlogDetailPrev(props) {
    return (
        <Link to={"/blog-post/" + props.url} className="btn-go">
            <div className="icon">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.785 13.75H25V16.25H9.785L16.49 22.955L14.7225 24.7225L5 15L14.7225 5.27753L16.49 7.04503L9.785 13.75Z" fill="black" />
                </svg>
            </div>
            <div className="text">
                {props.title}
            </div>
        </Link>
    )
}
