// src/components/CookieModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const CookieModal = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (!cookieConsent) {
            setShow(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setShow(false);
    };

    return (
        <div className={"cookie-modal " + (show ? "" : "d-none" )}>
            <div className='text'>This site uses cookies, we use cookies to improve your user experience.</div>
            <Button variant="dark" onClick={handleAccept}>OK</Button>
        </div>
    );
};

export default CookieModal;
