import React from 'react'

export default function ProjectTabItemContent(props) {
    return (
        <div>
            <div className="title">
                {props.title}
            </div>
            <div className="raw-content">
                {<div dangerouslySetInnerHTML={{ __html: props.rawHTML }} />}
            </div>
        </div>
    )
}
