import React from 'react'
import { Link } from 'react-router-dom'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton
} from "react-share";

export default function BlogDetailHeader(props) {
    return (
        <div className="header">
            <div className="category">{props.category}</div>
            <div className="share">

                <FacebookShareButton url={window.location.href}>
                    <Link>
                        <svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_130_4072)">
                                <path d="M2.29749 17V9.02311H0V6.15104H2.29749V3.69793C2.29749 1.77024 3.65463 0 6.78176 0C8.04789 0 8.98413 0.111435 8.98413 0.111435L8.91036 2.79346C8.91036 2.79346 7.95554 2.78492 6.9136 2.78492C5.78591 2.78492 5.60524 3.26203 5.60524 4.05391V6.15104H9L8.85229 9.02311H5.60524V17H2.29749Z" fill="black" />
                            </g>
                            <defs>
                                <clipPath id="clip0_130_4072">
                                    <rect width="9" height="17" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Link>
                </FacebookShareButton>

                <TelegramShareButton url={window.location.href}>
                    <Link>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mt-2 mb-2" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-brand-telegram"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4" /></svg>
                    </Link>
                </TelegramShareButton>

                <TwitterShareButton url={window.location.href}>
                    <Link>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.3215 2.41921C13.1181 2.5294 12.9089 2.62242 12.6952 2.69795C12.9482 2.34844 13.1411 1.9372 13.2589 1.4872C13.2853 1.38633 13.2579 1.27593 13.1901 1.20994C13.1223 1.14392 13.0281 1.13601 12.9534 1.19005C12.4996 1.51883 12.0099 1.75512 11.4965 1.89327C10.9793 1.27602 10.2768 0.924713 9.55062 0.924713C8.01775 0.924713 6.77066 2.44788 6.77066 4.32006C6.77066 4.46751 6.7783 4.61415 6.79343 4.75876C4.89129 4.55478 3.1229 3.41285 1.90856 1.59346C1.86529 1.52862 1.79862 1.49365 1.73035 1.50036C1.66207 1.50687 1.60068 1.55392 1.56618 1.62624C1.31989 2.14242 1.18968 2.7328 1.18968 3.33349C1.18968 4.15164 1.42882 4.92786 1.85128 5.53443C1.72282 5.48009 1.59818 5.41219 1.4792 5.33153C1.41534 5.2881 1.33739 5.28878 1.27393 5.3332C1.21045 5.37765 1.17064 5.45936 1.16894 5.5487C1.16864 5.56375 1.16864 5.57879 1.16864 5.59405C1.16864 6.81525 1.70676 7.91473 2.52949 8.51397C2.4588 8.50535 2.38818 8.49284 2.318 8.47647C2.24564 8.45957 2.17125 8.49055 2.12246 8.55796C2.0736 8.62531 2.05741 8.71919 2.07995 8.80491C2.38447 9.96618 3.1685 10.8203 4.11633 11.0807C3.3302 11.6821 2.43121 11.9971 1.48747 11.9971C1.29055 11.9971 1.09251 11.983 0.898692 11.955C0.802404 11.941 0.71034 12.0105 0.677536 12.1223C0.644731 12.2342 0.679508 12.359 0.761405 12.4231C1.97379 13.3726 3.37565 13.8744 4.81536 13.8744C7.64565 13.8744 9.41622 12.2443 10.4031 10.8767C11.6337 9.17151 12.3395 6.91447 12.3395 4.68437C12.3395 4.5912 12.3384 4.49713 12.336 4.40335C12.8216 3.95655 13.2396 3.41582 13.5797 2.79433C13.6314 2.69996 13.6258 2.57518 13.5659 2.48832C13.5062 2.40129 13.4066 2.37318 13.3215 2.41921Z" fill="black" />
                        </svg>
                    </Link>
                </TwitterShareButton>

                <LinkedinShareButton url={window.location.href}>
                    <Link>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" className="mt-3" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.47544 4.68172H7.8802V5.87957C8.2266 5.19064 9.11495 4.57165 10.4494 4.57165C13.0076 4.57165 13.615 5.94302 13.615 8.45914V13.1191H11.025V9.03216C11.025 7.59928 10.6786 6.79122 9.79675 6.79122C8.57365 6.79122 8.06538 7.66209 8.06538 9.03152V13.1191H5.47544V4.68172ZM1.03435 13.009H3.62429V4.57165H1.03435V13.009ZM3.99529 1.82049C3.99539 2.03757 3.95234 2.25251 3.86864 2.45281C3.78494 2.65311 3.66226 2.83478 3.50774 2.98725C3.19462 3.29845 2.77078 3.47264 2.32932 3.47157C1.88863 3.47128 1.46577 3.29753 1.15219 2.9879C0.998227 2.83491 0.875965 2.65304 0.792413 2.45271C0.70886 2.25238 0.665658 2.03754 0.665283 1.82049C0.665283 1.38214 0.840104 0.962571 1.15284 0.653074C1.46615 0.343031 1.88918 0.169209 2.32996 0.169403C2.77155 0.169403 3.195 0.343576 3.50774 0.653074C3.81983 0.962571 3.99529 1.38214 3.99529 1.82049Z" fill="black" />
                        </svg>
                    </Link>
                </LinkedinShareButton>
            </div>
            <img src={props.image} alt={""} className="img" />
        </div>
    )
}
