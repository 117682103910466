import React, { useEffect, useState } from 'react'
import { Row, Spinner } from 'react-bootstrap'
import BlogItem from "./BlogItem";
import { MainContext, useContext } from '../MainContext';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function BlogList() {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [items, setItems] = useState(<div className="d-flex justify-content-center align-items-center"><Spinner variant="dark" animation="border" role="status"></Spinner></div>);
  const [pageItems, setPageItems] = useState([]);

  const [page, setPage] = useState(1);
  const [data, setData] = useState(1);
  const { axiosInstance } = useContext(MainContext);

  function getPageParameter(url) {
    try {
      const urlObj = new URL(url);
      const page = urlObj.searchParams.get('page');
      return page;
    } catch (error) {
      //console.error("Invalid URL", error);
      return 0;
    }
  }



  useEffect(() => {


    axiosInstance.post("/blog?page=" + searchParams.get("page")).then((res) => {
      setData(res.data);

      let tempComponents = [];
      res.data.data.map((el, key) => {
        return tempComponents.push(<BlogItem key={key} slug={el.slug} thumbnail={el.thumbnail} chainIcon={el.chain.icon} title={el.title} category={el.category.name} date={el.date} />);
      });
      setItems(tempComponents);

      res.data.links.shift();
      res.data.links.pop();

      tempComponents = [];

      setPage(page < 1 ? 1 : page);

      let prev_page_url = getPageParameter(res.data.prev_page_url) ?? res.data.current_page;
      tempComponents.push(<div key={0} onClick={() => { setPage(prev_page_url); navigate("/blog?page=" + prev_page_url); }} className={"page " + (page == prev_page_url ? "active" : "")}><svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.785 8.74997H20V11.25H4.785L11.49 17.955L9.7225 19.7225L0 9.99997L9.7225 0.277466L11.49 2.04497L4.785 8.74997Z" fill="black" /></svg></div>);

      res.data.links.map((el, key) => {
        return tempComponents.push(<div key={key} onClick={() => { setPage(el.label); navigate("/blog?page=" + el.label); }} className={"page " + (page == el.label ? "active" : "")}>{el.label}</div>);
      });

      let next_page_url = getPageParameter(res.data.next_page_url);
      tempComponents.push(<div key={0} onClick={() => { setPage(next_page_url); navigate("/blog?page=" + next_page_url); }} className={"page " + (page == next_page_url ? "active" : "")}><svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.215 8.74997H0V11.25H15.215L8.51 17.955L10.2775 19.7225L20 9.99997L10.2775 0.277466L8.51 2.04497L15.215 8.74997Z" fill="black" /></svg></div>);

      setPageItems(tempComponents);
    });




  }, [page]);




  return (
    <div>
      <div className="blog-list">
        <Row>
          {items}
        </Row>
      </div>

      <div className="pagination">

        {pageItems}

      </div>
    </div>
  )
}
