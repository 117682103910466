import React from 'react'

export default function HomePhaseSliderItem(props) {
    return (
        <div className="phase-item">
            <div className="phase-title">
                <div className="phase-count">Phase {props.phaseCount}</div>
                <div className="phase-name">{props.phaseName}</div>
            </div>
            <div className="title">{props.title}</div>
            <div className="description">{props.description}</div>
        </div>
    )
}
