import React from 'react'
import { Container } from 'react-bootstrap'

export default function HomeEventScene() {
    return (
        <Container className="mb-5 presale-title event-scene">
            <div className="text-center">
                <img src={"/images/dotted-logo.png"} alt={""} />
                <div className="text">
                    <div className="subtitle">Event Scene Invfestigations</div>
                    <div className="title">PREPARED <span>PLATFORM</span></div>
                </div>
            </div>
        </Container>
    )
}
