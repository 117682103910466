import React, { useContext, useRef } from 'react'
import { Container } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Link } from 'react-router-dom';
import { MainContext } from '../MainContext';

export default function Footer() {

  const swiperRef = useRef();

  const { systemSettings } = useContext(MainContext);

  let items = [];
  let item;
  for (let index = 1; index < 18; index++) {
    item = <SwiperSlide className="item" key={index}>
      <img src={"/images/partners/partner-" + index + ".png"} alt={""} />
    </SwiperSlide>
    items.push(item);
  }

  return (
    <footer>
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        spaceBetween={0}
        centeredSlides={false}
        slidesPerView={6.5}
        autoplay={{
          delay: 0,
          disableOnInteraction: true,
          pauseOnMouseEnter: true
        }}
        speed={10000}
        loop={1}
        modules={[Autoplay, Pagination, Navigation]}
        className="slider ms-3 me-3 p-4"
        breakpoints={{
          1450: {
            slidesPerView: 6.5,
          },
          1024: {
            slidesPerView: 3.5,
          },
          768: {
            slidesPerView: 3,
          },
          550: {
            slidesPerView: 1
          },
          430: {
            slidesPerView: 1
          },
          375: {
            slidesPerView: 1
          },
          320: {
            slidesPerView: 1
          }
        }}
      >
        {items}

      </Swiper>
      <Container>
        <div className="content">

          <div className="logo-zone">

            <img src={"/images/footer-logo.png"} alt="" />

            <div className="text">
            BondPAD is a multi-chain launchpad only for memecoins. alt satırına da: One application for multi-airdrop!: <Link to={"http://t.me/BondTAPbot"}>BondTAPbot</Link>
            </div>

            <div className="d-flex">
              {
                systemSettings ? <>
                  <Link className="btn-social" to={systemSettings.telegram_url ? systemSettings.telegram_url : ""}>
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.9635 0.293201C17.949 0.230515 17.9172 0.172574 17.8712 0.125293C17.8253 0.0780122 17.7668 0.043085 17.7019 0.0240772C17.4656 -0.0199116 17.221 -0.00341669 16.9937 0.0718506C16.9937 0.0718506 1.22286 5.42089 0.322298 6.01328C0.127672 6.14068 0.0629846 6.21446 0.0309221 6.30205C-0.124891 6.72351 0.359985 6.9093 0.359985 6.9093L4.42462 8.15937C4.49328 8.17057 4.56375 8.16658 4.6305 8.1477C5.55525 7.59671 13.9337 2.60385 14.418 2.43611C14.494 2.41434 14.5502 2.43611 14.5378 2.48919C14.3398 3.12935 7.10438 9.19712 7.06444 9.23428C7.04503 9.24931 7.02977 9.26856 7.01997 9.29038C7.01017 9.3122 7.00613 9.33593 7.00819 9.35955L6.63019 13.1023C6.63019 13.1023 6.47156 14.2701 7.70682 13.1023C8.58263 12.2748 9.42301 11.59 9.84432 11.2572C11.2421 12.1676 12.7463 13.1745 13.3948 13.699C13.5036 13.7987 13.6327 13.8767 13.7741 13.9284C13.9156 13.98 14.0667 14.0041 14.2183 13.9994C14.8371 13.9771 15.0058 13.3386 15.0058 13.3386C15.0058 13.3386 17.8791 2.42762 17.9758 0.965215C17.9848 0.821895 17.9978 0.730063 17.9989 0.631862C18.004 0.517964 17.9921 0.403953 17.9635 0.293201Z" fill="black" />
                    </svg>
                  </Link>

                  <Link className="btn-social" to={systemSettings.twitter_url ? systemSettings.twitter_url : ""}>
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.0001 2.54799C17.3272 2.82334 16.6543 3.04362 15.8692 3.09869C16.6543 2.65813 17.215 1.94222 17.4954 1.11617C16.7664 1.55673 15.9814 1.83208 15.1402 1.99729C14.4674 1.28138 13.5141 0.84082 12.5047 0.84082C10.486 0.84082 8.8038 2.43785 8.8038 4.47544C8.8038 4.75079 8.85987 5.02613 8.91595 5.30148C5.83184 5.13627 3.14025 3.70446 1.28978 1.50166C0.953332 2.05236 0.785108 2.65813 0.785108 3.31897C0.785108 4.58558 1.458 5.68697 2.41128 6.34781C1.79445 6.34781 1.23371 6.1826 0.729033 5.90725V5.96232C0.729033 7.72456 2.01875 9.15638 3.701 9.4868C3.36455 9.54187 3.08417 9.59694 2.74772 9.59694C2.52343 9.59694 2.29913 9.59694 2.07483 9.54187C2.52343 10.9737 3.92529 12.02 5.49539 12.0751C4.26174 13.0663 2.63558 13.617 0.897257 13.617C0.616884 13.617 0.280435 13.617 6.10352e-05 13.562C1.6823 14.5532 3.64492 15.159 5.71969 15.159C12.5047 15.159 16.2057 9.65201 16.2057 4.86093C16.2057 4.69572 16.2057 4.53051 16.2057 4.42037C16.8786 3.86967 17.4954 3.2639 18.0001 2.54799Z" fill="black" />
                    </svg>
                  </Link>

                  <Link className="btn-social" to={systemSettings.discord_url ? systemSettings.discord_url : ""}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="black"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="icon icon-tabler icons-tabler-outline icon-tabler-brand-discord"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M8 12a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                      <path d="M14 12a1 1 0 1 0 2 0a1 1 0 0 0 -2 0" />
                      <path d="M15.5 17c0 1 1.5 3 2 3c1.5 0 2.833 -1.667 3.5 -3c.667 -1.667 .5 -5.833 -1.5 -11.5c-1.457 -1.015 -3 -1.34 -4.5 -1.5l-.972 1.923a11.913 11.913 0 0 0 -4.053 0l-.975 -1.923c-1.5 .16 -3.043 .485 -4.5 1.5c-2 5.667 -2.167 9.833 -1.5 11.5c.667 1.333 2 3 3.5 3c.5 0 2 -2 2 -3" />
                      <path d="M7 16.5c3.5 1 6.5 1 10 0" />
                    </svg>

                  </Link>

                </>
                  : ""
              }
            </div>

          </div>

          <div className="links">

            <div className="link-menu">
              <div className="title">BondPAD</div>
              <div className="link-border"><div className="length"></div></div>
              <Link className="link" to={"/"}>MEMEPAD</Link>
              <Link className="link" to={"/blog"}>BONDDAILY</Link>
              <Link className="link" to={systemSettings ? systemSettings.submit_meme_url : ""}>SUBMIT MEME</Link>
              <Link className="link" to={""}>DOCS</Link>
            </div>

            <div className="link-menu">
              <div className="title">$BOND</div>
              <div className="link-border"><div className="length"></div></div>
              <Link className="link" to={systemSettings ? systemSettings.DexScreener : ""}>DexScreener</Link>
              <Link className="link" to={systemSettings ? systemSettings.Jupiter : ""}>Jupiter</Link>
              <Link className="link" to={systemSettings ? systemSettings.CoinMarketCap : ""}>CoinMarketCap</Link>
              <Link className="link" to={systemSettings ? systemSettings.CoinGecko : ""}>CoinGecko</Link>
            </div>

            <div className="link-menu">
              <div className="title">CONNECT</div>
              <div className="link-border"><div className="length"></div></div>
              <Link className="link" to={systemSettings ? systemSettings.twitter_url : ""}>Twitter</Link>
              <Link className="link" to={systemSettings ? systemSettings.telegram_url : ""}>Telegram</Link>
              <Link className="link" to={systemSettings ? systemSettings.discord_url : ""}>Discord</Link>
              <Link className="link" to={systemSettings ? systemSettings.youtube_url : ""}>Youtube</Link>
            </div>

          </div>

        </div>
        <div className="footer-description">BondPAD™ are registered trademarks. Memecoin Community Funding Platform Built to Power the Memecoin Economy! Owns its own platform token $BOND! All rights reserved.</div>
      </Container>
    </footer>
  )
}
