import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { MainContext, useContext } from '../MainContext';



export default function Header(props) {

    let mobileHeader = () => {
        return (
        <Navbar expand="lg" className="bg-black d-block d-sm-none">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={"/images/main-logo-m.png"} alt={""} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Link className='nav-link' to="/">Memepad</Link>
                            <Link className='nav-link' to="/blog">Bonddaily</Link>
                            <Link className='nav-link' to={systemSettings ? systemSettings.submit_meme_url : ""}>Submit Meme</Link>
                            <Link className='nav-link' onClick={() => { connectPhantom() }}>Wallet</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }

    const { connectPhantom, systemSettings, bearerToken } = useContext(MainContext);
    
    /*
        <Link to={"/profile"}>
            <button className="connect-btn">
                <img src={"/images/connect-btn-bg.png"} alt={""} />
                <span>Wallet</span>
            </button>
        </Link>
    */

    return (
        <header>

            <div className="header-banner">
                <Container>
                    <button className="btn-price">
                        <img src={"/images/price-btn-bg.png"} alt={""} />
                        <div className="fw-bold coin">BOND = </div>
                        <div className="price">{props.price}</div>
                    </button>
                    <div className="text">THE FIRST MULTI-CHAIN LAUNCHPAD FOR ONLY MEMECOINS, BondPAD!</div>
                    <Link to={"/airdrop"} className="btn-airdrop">
                        <img src={"/images/airdrop-btn-bg.png"} alt={""} />
                        <span className='text-dark'>Airdrop</span>
                    </Link>
                </Container>
            </div>

            <Container className="only-web">
                <div className="menu">

                    <Link to={"/"}>
                        <button>
                            <img src={"/images/menu-btn-bg.png"} alt={""} />
                            <span>Memepad</span>
                        </button>
                    </Link>
                    <Link to={"/blog"}>
                        <button>
                            <img src={"/images/menu-btn-bg.png"} alt={""} />
                            <span>Bonddaily</span>
                        </button>
                    </Link>

                    <Link to={"/"} className="logo">
                        <img src={"/images/main-logo.png"} alt="Logo" />
                    </Link>

                    <Link to={systemSettings ? systemSettings.submit_meme_url : ""}>
                        <button>
                            <img src={"/images/menu-btn-bg.png"} alt={""} />
                            <span>Submit Meme</span>
                        </button>
                    </Link>

                    <button className="connect-btn" onClick={() => { connectPhantom() }}>
                        <img src={"/images/connect-btn-bg.png"} alt={""} />
                        <span>{ bearerToken !== -1 ? "Account" : "Wallet" }</span>
                    </button>


                </div>
            </Container>



            {mobileHeader()}

        </header>
    )
}
