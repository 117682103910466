import React, { useEffect, useRef, useState } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { MainContext, useContext } from '../MainContext';

import 'swiper/css';
import 'swiper/css/autoplay';
import HomePhaseSliderItem from './HomePhaseSliderItem';

export default function HomePhaseSlider() {

  const swiperRef = useRef();

  const { axiosInstance } = useContext(MainContext);

  const [phases, setPhases] = useState([]);

  useEffect(() => {

    axiosInstance.post("/home/phases").then((res) => {
      let data = res.data;
      let temp = [];
      data.map((el, key) => {
        temp.push(<>
          <SwiperSlide key={key}>
            <HomePhaseSliderItem phaseCount={el.step} phaseName={el.name} title={el.title} description={el.description} />
          </SwiperSlide>
        </>)
      });
      setPhases(temp);
    });

  }, []);

  /*
  let items = [];
  let item;
  for (let index = 1; index < 5; index++) {
    item = <SwiperSlide key={index}>
      <HomePhaseSliderItem phaseCount={index} phaseName={"Launch Initiated"} title={"Join The Pre-Sale With Confidence"} description={" Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."} />
    </SwiperSlide>
    items.push(item);
  }
  */

  return (
    <Container className="phase-slider">
      <div className="d-flex justify-content-between align-items-center">
        <button className="btn-action" onClick={() => swiperRef.current.slidePrev()}>
          <i className="fas fa-arrow-left"></i>
        </button>
        <Swiper
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          spaceBetween={30}
          centeredSlides={false}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
            pauseOnMouseEnter: true
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="slider-bg ms-3 me-3"
        >
          {phases.length > 0 ? phases : <Spinner animation="border" variant="dark" />}

        </Swiper>
        <button className="btn-action" onClick={() => swiperRef.current.slideNext()}>
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </Container>
  )
}
