import React from 'react'
import { Container } from 'react-bootstrap'
import BlogHeader from '../components/BlogHeader';
import BlogList from '../components/BlogList';

export default function Blog() {
    return (
        <Container className="mt-5 mb-5 blog">
            <BlogHeader />
            <BlogList/>
        </Container>
    )
}
