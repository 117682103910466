import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { Container, Spinner } from 'react-bootstrap'
import HomeProjectItem from './HomeProjectItem';
import { MainContext, useContext } from '../MainContext';

export default function HomeProjects() {

    const { axiosInstance } = useContext(MainContext);

    const [isLoading, setLoading] = useState(1);
    const [latestProjects, setLatestProjects] = useState([]);
    const [upcomingProjects, setUpcomingProjects] = useState([]);
    const [releasedProjects, setReleasedProjects] = useState([]);

    const [latestPage, setLatestPage] = useState(1);
    const [upcomingPage, setUpcomingPage] = useState(1);
    const [releasedPage, setReleasedPage] = useState(1);

    const [activeTab, setActiveTab] = useState('latest');


    useEffect(() => {
        // project tabs
        document.querySelectorAll(".nav-item a").forEach((el) => {
            el.classList.remove("active");
        });
        document.getElementById(activeTab).classList = "active";
    
        let params = new URLSearchParams({
            latest: latestPage,
            upcoming: upcomingPage,
            released: releasedPage,
        });
    
        axiosInstance.post("/home/presales?" + params.toString()).then((res) => {
            let data = res.data;
    
            // Latest Projects
            if (data.latest.data.length === 0) {
                const latestLoader = document.querySelector('.latest-loader');
                if (latestLoader) latestLoader.remove();
            } else {
                let tempLatest = [...latestProjects];
                data.latest.data.forEach((el) => {
                    if (!tempLatest.some(project => project.key === el.id.toString())) {
                        tempLatest.push(<HomeProjectItem key={el.id} slug={el.slug} logo={el.token_icon} networkIcon={el.chain.icon} thumbnail={el.thumbnail} description={el.description} maxSize={el.max_send_size} hardcap={el.hardcap} chain={el.chain.name} launch={el.presale_finish} />);
                    }
                });
                setLatestProjects(tempLatest);
            }
    
            // Upcoming Projects
            if (data.upcoming.data.length === 0) {
                const upcomingLoader = document.querySelector('.upcoming-loader');
                if (upcomingLoader) upcomingLoader.remove();
            } else {
                let tempUpcoming = [...upcomingProjects];
                data.upcoming.data.forEach((el) => {
                    if (!tempUpcoming.some(project => project.key === el.id.toString())) {
                        tempUpcoming.push(<HomeProjectItem key={el.id} slug={el.slug} logo={el.token_icon} networkIcon={el.chain.icon} thumbnail={el.thumbnail} description={el.description} maxSize={el.max_send_size} hardcap={el.hardcap} chain={el.chain.name} launch={el.presale_finish} />);
                    }
                });
                setUpcomingProjects(tempUpcoming);
            }
    
            // Released Projects
            if (data.released.data.length === 0) {
                const releasedLoader = document.querySelector('.released-loader');
                if (releasedLoader) releasedLoader.remove();
            } else {
                let tempReleased = [...releasedProjects];
                data.released.data.forEach((el) => {
                    if (!tempReleased.some(project => project.key === el.id.toString())) {
                        tempReleased.push(<HomeProjectItem key={el.id} slug={el.slug} logo={el.token_icon} networkIcon={el.chain.icon} thumbnail={el.thumbnail} description={el.description} maxSize={el.max_send_size} hardcap={el.hardcap} chain={el.chain.name} launch={el.presale_finish} />);
                    }
                });
                setReleasedProjects(tempReleased);
            }
    
            setLoading(0);
        });
    
    }, [activeTab, latestPage, upcomingPage, releasedPage]);


    return (
        <Container className="home-projects">
            <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="latest"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
            >
                <div className="tab-menu">
                    <div className="filter-text">
                        Filter By:
                    </div>
                    <div className="tab-items">
                        <Nav.Item>
                            <Nav.Link className="tab-menu-item" eventKey="latest" id="latest">Latest</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link className="tab-menu-item" eventKey="upcoming" id="upcoming">Upcoming</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link className="tab-menu-item" eventKey="released" id="released">Released</Nav.Link>
                        </Nav.Item>
                    </div>
                </div>

                <Tab.Content className="text-center">
                    <Tab.Pane eventKey="latest">
                        {isLoading === 0 ? latestProjects : <Spinner animation="border" variant="dark" />}
                        <div className="d-flex justify-content-center align-items-center mt-5 latest-loader">
                            <button className="load-more mt-3" onClick={() => { setLatestPage(latestPage + 1); }}>
                                <img src={"/images/load-more-btn-bg.png"} alt={""} />
                                <span>Load More</span>
                            </button>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="upcoming">
                        {isLoading === 0 ? upcomingProjects : <Spinner animation="border" variant="dark" />}
                        <div className="d-flex justify-content-center align-items-center mt-5 upcoming-loader">
                            <button className="load-more mt-3" onClick={() => { setUpcomingPage(upcomingPage + 1); }}>
                                <img src={"/images/load-more-btn-bg.png"} alt={""} />
                                <span>Load More</span>
                            </button>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="released">
                        {isLoading === 0 ? releasedProjects : <Spinner animation="border" variant="dark" />}
                        <div className="d-flex justify-content-center align-items-center mt-5 released-loader">
                            <button className="load-more mt-3" onClick={() => { setReleasedPage(releasedPage + 1); }}>
                                <img src={"/images/load-more-btn-bg.png"} alt={""} />
                                <span>Load More</span>
                            </button>
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>


        </Container>
    )
}
