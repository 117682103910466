import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { MainContext, useContext } from '../MainContext';

export default function HomeSubmitMeme() {

    const { systemSettings } = useContext(MainContext);


    return (
        <Container className="submit-meme mb-5">
            <img src={"/images/submit-meme-bg.png"} alt={""} />
            <div className="content">
                <div className="title">
                    Submit Your
                </div>
                <div className="colored-title">
                    Meme
                </div>
                <div className="description">
                    { systemSettings ? systemSettings.home_submit_meme_text : "" }
                </div>
                <Link to={ systemSettings ? systemSettings.submit_meme_url : "" }>
                    <button>
                        <img src={"/images/load-more-btn-bg.png"} alt={""} />
                        <span>Submit Meme</span>
                    </button>
                </Link>
            </div>
        </Container>
    )
}
