import React from 'react'
import HomeSubscribe from '../components/HomeSubscribe'
import { Container } from 'react-bootstrap'
import HomePriceSwiper from '../components/HomePriceSwiper'
import HomePreSaleTitle from '../components/HomePreSaleTitle'
import HomeProjects from '../components/HomeProjects'
import HomePhaseSlider from '../components/HomePhaseSlider'
import HomeSubmitMeme from '../components/HomeSubmitMeme'
import HomeEventScene from '../components/HomeEventScene'
import HomeNews from '../components/HomeNews'
import HomeRoadMap from '../components/HomeRoadMap'
import { Link } from 'react-router-dom'

export default function Home() {

  return (
    <Container className="mt-5 home">
      <HomePriceSwiper />
      <Link to={"https://x.com/007BondPad/status/1818708218341998717"} target='blank' className='d-flex justify-content-center align-items-center mt-3 mb-2'><img src={"/images/lppowered.png"} alt='partner'/></Link>
      <HomeProjects />
      <HomePhaseSlider />
      <HomeRoadMap />
      <HomeSubmitMeme />
      <HomeEventScene />
      <HomeNews />
      <HomeSubscribe />
    </Container>
  )
}
