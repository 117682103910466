import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class BlogHeader extends Component {
    render() {
        return (
            <div className="blog-header">
                <div className="title">Our Blog</div>
                <div className="description">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vulputate rutrum velit, in tincidunt sapienc </div>
                <div className="breadcrumb">
                    <Link to={"/"}>Home</Link>
                    <svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.99996 6.41666V4.66666L9.33329 6.99999L6.99996 9.33332V7.58332H4.66663V6.41666H6.99996ZM6.99996 1.16666C10.22 1.16666 12.8333 3.77999 12.8333 6.99999C12.8333 10.22 10.22 12.8333 6.99996 12.8333C3.77996 12.8333 1.16663 10.22 1.16663 6.99999C1.16663 3.77999 3.77996 1.16666 6.99996 1.16666ZM6.99996 11.6667C9.57829 11.6667 11.6666 9.57832 11.6666 6.99999C11.6666 4.42166 9.57829 2.33332 6.99996 2.33332C4.42163 2.33332 2.33329 4.42166 2.33329 6.99999C2.33329 9.57832 4.42163 11.6667 6.99996 11.6667Z" fill="#FEBC00" />
                    </svg>

                    <Link to={"/"}>Bondpad</Link>
                    <svg width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.99996 6.41666V4.66666L9.33329 6.99999L6.99996 9.33332V7.58332H4.66663V6.41666H6.99996ZM6.99996 1.16666C10.22 1.16666 12.8333 3.77999 12.8333 6.99999C12.8333 10.22 10.22 12.8333 6.99996 12.8333C3.77996 12.8333 1.16663 10.22 1.16663 6.99999C1.16663 3.77999 3.77996 1.16666 6.99996 1.16666ZM6.99996 11.6667C9.57829 11.6667 11.6666 9.57832 11.6666 6.99999C11.6666 4.42166 9.57829 2.33332 6.99996 2.33332C4.42163 2.33332 2.33329 4.42166 2.33329 6.99999C2.33329 9.57832 4.42163 11.6667 6.99996 11.6667Z" fill="#FEBC00" />
                    </svg>

                    <Link to={"/"}>Our Blog</Link>
                </div>
            </div>
        )
    }
}
